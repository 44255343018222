document.addEventListener('DOMContentLoaded', () => {
  const showMessageElements = document.querySelectorAll('.contact__show-message');

  if (showMessageElements) {
    const handleShowMessageChange = (event) => {
      const checkbox = event.target;
      const form = checkbox.closest('form');
      const messageInput = form.querySelector('.contact__message');
      const messageRow = messageInput.closest('.form-widget').parentElement;

      if (checkbox.checked) {
        messageRow.style.display = 'unset';
      } else {
        messageRow.style.display = 'none';

        // clear the message
        messageInput.innerHTML = '';
        messageInput.innerText = '';
        messageInput.value = '';
      }

      const resizeEvent = window.document.createEvent('UIEvents');
      resizeEvent.initUIEvent('resize', true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    };

    showMessageElements.forEach((element) => {
      element.addEventListener('change', handleShowMessageChange);
    });
  }
});
