import $ from 'jquery';

$(document).ready(() => {
  const body = document.querySelector('body');
  const nav = document.querySelector('.header__nav');
  const navIcon = document.querySelector('#nav-icon1');
  const navLinks = nav.querySelectorAll('a');

  $(navIcon).click(() => {
    body.classList.toggle('menu-open');
    nav.classList.toggle('is-open');
    navIcon.classList.toggle('open');
  });

  $(navLinks).click(() => {
    body.classList.remove('menu-open');
    nav.classList.remove('is-open');
    navIcon.classList.remove('open');
  });
});
