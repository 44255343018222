import $ from 'jquery';

import 'masonry-layout/masonry';
import imagesLoaded from 'imagesloaded';

const Masonry = require('masonry-layout');

$(document).ready(() => {
  const options = {
    // options
    itemSelector: '.gallery__item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    horizontalOrder: false,
    gutter: 10,
  };

  if (document.querySelector('.ballroom-gallery')) {
    const ballroomMasonry = new Masonry('.ballroom-gallery', options);

    // eslint-disable-next-line prefer-arrow-callback
    imagesLoaded('.ballroom-gallery').on('progress', () => {
      ballroomMasonry.layout();
    });
  }

  if (document.querySelector('.garden-room-gallery')) {
    const gardenRoomMasonry = new Masonry('.garden-room-gallery', options);

    // eslint-disable-next-line prefer-arrow-callback
    imagesLoaded('.garden-room-gallery')
      .on('progress', () => {
        gardenRoomMasonry.layout();
      });
  }
});
