window.addEventListener('load', () => {
  if (document.querySelector('.header__nav')) {
    const nav = document.querySelector('.header__nav');

    const handleSticky = () => {
      const contentGroup = nav.closest('.header__content-group');
      const navOffset = contentGroup.offsetTop;

      if (navOffset < window.pageYOffset) {
        nav.parentElement.classList.add('is-sticky');
      } else {
        nav.parentElement.classList.remove('is-sticky');
      }
    };

    window.addEventListener('scroll', handleSticky);

    handleSticky();
  }
});
