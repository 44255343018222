document.addEventListener('DOMContentLoaded', () => {
  const inputs = document.querySelectorAll('.js-event-estimation-input');
  const wizardContent = document.querySelector('.wizard__content');
  const wizardSteps = document.querySelectorAll('.wizard__step');
  const forwardButtons = document.querySelectorAll('.js-forward-step-button');
  const backButtons = document.querySelectorAll('.js-back-step-button');
  const priceBar = document.querySelector('.js-progress-bar');
  const priceElements = document.querySelectorAll('.js-price');
  const deluxeItemsElement = document.querySelector('.wizard__deluxe__bottom');
  const hiddenFormElement = document.querySelector('.contact__extra-data');

  const summaryRoomName = document.querySelector('.js-summary-room-name');
  const summaryRoomPrice = document.querySelector('.js-summary-room-price');
  const summaryDecorName = document.querySelector('.js-summary-decor-name');
  const summaryDecorPrice = document.querySelector('.js-summary-decor-price');
  const summaryTotal = document.querySelector('.js-summary-total');

  if (inputs.length) {
    // 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'
    const daysOfTheWeek = [
      'mon',
      'tue',
      'wed',
      'thu',
      'fri',
      'sat',
    ];

    const timeOfDaySlots = [
      'am', // 10-15
      'pm', // 17-22
      'allday', // 10-22
    ];

    const rooms = {
      ballroom: 'Ballroom',
      gardenroom: 'Garden Room',
    };

    const slotPrices = {
      ballroom: [
        {
          days: ['mon', 'tue', 'wed'],
          hours: ['am'],
          price: 1100,
        },
        {
          days: ['mon', 'tue', 'wed'],
          hours: ['pm'],
          price: 1800,
        },
        {
          days: ['mon', 'tue', 'wed'],
          hours: ['allday'],
          price: 2800,
        },
        {
          days: ['thu', 'fri', 'sat'],
          hours: ['am'],
          price: 1800,
        },
        {
          days: ['thu', 'fri', 'sat'],
          hours: ['pm'],
          price: 2500,
        },
        {
          days: ['thu', 'fri', 'sat'],
          hours: ['allday'],
          price: 4200,
        },
      ],
      gardenroom: [
        {
          days: ['mon', 'tue', 'wed'],
          hours: ['am'],
          price: 600,
        },
        {
          days: ['thu', 'fri', 'sat'],
          hours: ['am'],
          price: 800,
        },
      ],
    };

    const deluxePrices = {
      ballroom: 999,
      gardenroom: 750,
    };

    const getSlot = (room, day, hours) => {
      if (!(room in slotPrices)) {
        return null;
      }

      if (!daysOfTheWeek.includes(day)) {
        return null;
      }

      if (!timeOfDaySlots.includes(hours)) {
        return null;
      }

      const roomPriceSlots = slotPrices[room];

      let slot = null;
      for (let i = 0; i < roomPriceSlots.length; i += 1) {
        slot = roomPriceSlots[i];
        if (slot.days.includes(day) && slot.hours.includes(hours)) {
          return slot;
        }
      }

      return null;
    };

    // eslint-disable-next-line arrow-body-style
    const formatPrice = (price) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price);
    };

    const updateSelection = () => {
      let room;
      let day;
      let hours;
      let deluxe = false;

      const dayElement = document.querySelector('input[name="day"]:checked');
      if (dayElement) {
        day = dayElement.value;
      }

      const hoursElement = document.querySelector('input[name="hours"]:checked');
      if (hoursElement) {
        hours = hoursElement.value;
      }

      const roomKeys = Object.getOwnPropertyNames(rooms);
      for (let i = 0; i < roomKeys.length; i += 1) {
        const roomKey = roomKeys[i];
        const roomInput = document.getElementById(`room-${roomKey}`);

        if (getSlot(roomKey, day, hours)) {
          // enable room selection
          roomInput.removeAttribute('disabled');
        } else {
          // disable room selection
          roomInput.setAttribute('disabled', null);
          roomInput.checked = false;
        }
      }

      const roomElement = document.querySelector('input[name="room"]:checked');
      if (roomElement) {
        room = roomElement.value;
      }

      const deluxeElement = document.querySelector('input[name="deluxe"]:checked');
      if (deluxeElement) {
        deluxe = true;
      }

      document.querySelectorAll('.js-display-selected-day').forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.innerText = day;
      });

      document.querySelectorAll('.js-display-selected-hours').forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.innerText = hours;
      });

      const slot = getSlot(room, day, hours);

      // calculate and format price
      let slotPrice = 0;
      if (slot) {
        slotPrice = slot.price;
      }

      let deluxePrice = 0;
      if (deluxe && slot) {
        deluxePrice = deluxePrices[room];
      }

      const price = slotPrice + deluxePrice;
      const formattedPrice = `$${new Intl.NumberFormat('en-US').format(price)}`;

      // update price bar and last step price
      priceElements.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.innerHTML = formattedPrice;
      });

      priceBar.style.width = `${100 * price / 4200}%`;

      if (!slot) {
        return;
      }

      // update deluxe decor step
      // deluxeItemsElement.innerHTML = deluxeItemsElement.getAttribute(`data-content-${room}`);

      // update summary
      summaryRoomName.innerHTML = rooms[room];
      summaryRoomPrice.innerHTML = formatPrice(slot.price);
      summaryDecorName.innerHTML = `${rooms[room]} Custom Deluxe Decor Package`;

      if (deluxe) {
        summaryDecorName.classList.remove('text-strike');
        summaryDecorPrice.innerHTML = formatPrice(deluxePrices[room]);
      } else {
        summaryDecorName.classList.add('text-strike');
        summaryDecorPrice.innerHTML = formatPrice(0);
      }

      summaryTotal.innerHTML = formatPrice(price);

      // update hidden form element
      hiddenFormElement.value = JSON.stringify({
        Room: rooms[room],
        'Day of the week': day.charAt(0).toUpperCase() + day.slice(1),
        'Time of day': hours.toUpperCase(),
        'Room price': formatPrice(slot.price),
        'Custom deluxe decor package': deluxe ? 'Yes' : 'No',
        'Custom deluxe decor package price': deluxe ? formatPrice(deluxePrices[room]) : formatPrice(0),
        Total: formatPrice(price),
      });
    };

    const handleInputChange = (event) => {
      const label = event.target.closest('label');
      if (label.classList.contains('disabled')) {
        return;
      }

      const step = event.target.closest('.wizard__step');
      const forwardButton = step.querySelector('.js-forward-step-button');

      forwardButton.removeAttribute('disabled');

      updateSelection();
    };

    inputs.forEach((element) => {
      element.addEventListener('change', handleInputChange);
    });

    const updateWizardHeight = (element = null) => {
      let activeStep = element;
      if (!element) {
        activeStep = document.querySelector('.wizard__step.is-active');
      }

      wizardContent.style.height = `${activeStep.offsetHeight}px`;
    };

    updateWizardHeight();

    const handleStepButtonClick = (event) => {
      const button = event.target;
      const activateStepClass = button.getAttribute('data-selector');

      wizardSteps.forEach((element) => {
        if (element.classList.contains(activateStepClass)) {
          element.classList.add('is-active');
          updateWizardHeight(element);
        } else {
          element.classList.remove('is-active');
        }

        element.addEventListener('', () => {
          updateWizardHeight();
        });
      });
    };

    forwardButtons.forEach((element) => {
      element.addEventListener('click', handleStepButtonClick);
    });

    backButtons.forEach((element) => {
      element.addEventListener('click', handleStepButtonClick);
    });

    window.addEventListener('resize', () => {
      updateWizardHeight();
    });
  }
});
