import $ from 'jquery';

import 'slider-pro/dist/js/jquery.sliderPro';

$(document).ready(() => {
  $('.slider-pro').sliderPro({
    width: 1000,
    height: 675,

    // auto play
    autoplay: true,
    autoplayOnHover: 'pause',
    autoplayDelay: 10000,


    arrows: true,
    buttons: true,
    smallSize: 500,
    mediumSize: 1000,
    largeSize: 3000,
    fade: false,

    // thumbnail
    thumbnailArrows: true,
    thumbnailWidth: 120,
    thumbnailHeight: 120,
    thumbnailsPosition: 'bottom',
    thumbnailPointer: true,
    centerImage: true,
    imageScaleMode: 'cover',
    allowScaleUp: true,
    startSlide: 0,
    loop: true,
    slideDistance: 5,
    autoplayDirection: 'normal',
    touchSwipe: true,
    fullScreen: true,
  });
});
