document.addEventListener('DOMContentLoaded', () => {
  const subMenuTrigger = document.querySelectorAll('.sub-menu-trigger');

  const mouseOut = (event) => {
    const parent = event.target.closest('.header__nav__item');
    const trigger = parent.querySelector('.sub-menu-trigger');
    const subNav = trigger.nextElementSibling;

    setTimeout(() => {
      const hoveredElements = document.querySelectorAll(':hover');

      for (let i = 0; i < hoveredElements.length; i += 1) {
        if (
          hoveredElements[i] === trigger
          || hoveredElements[i] === subNav
          || hoveredElements[i] === parent
        ) {
          return;
        }
      }

      subNav.classList.remove('is-shown');
    }, 300, parent, trigger, subNav);
  };

  subMenuTrigger.forEach((element) => {
    element.addEventListener('mouseover', (event) => {
      const subNav = event.target.nextElementSibling;

      if (subNav) {
        subNav.classList.add('is-shown');
      }
    });

    element.addEventListener('mouseout', mouseOut);
    element.nextElementSibling.addEventListener('mouseout', mouseOut);
    element.closest('.header__nav__item').addEventListener('mouseout', mouseOut);
  });
});
