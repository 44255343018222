window.addEventListener('load', () => {
  const scrollToTopBtn = document.querySelector('.back-to-top');

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function handleScroll() {
    if (document.documentElement.scrollTop > 250) {
      // show button
      scrollToTopBtn.classList.add('is-shown');
    } else {
      // hide button
      scrollToTopBtn.classList.remove('is-shown');
    }
  }

  scrollToTopBtn.addEventListener('click', scrollToTop);
  document.addEventListener('scroll', handleScroll);
  handleScroll();
});
